<template>
  <div id="personal-center">
    <div class="title">
      <div>
        <h1><img src="@/assets/images/center.png" alt="" /></h1>
        <router-link to="/home">返回交易中心首页</router-link>
      </div>
    </div>
    <div class="content">
      <el-container>
        <el-aside width="180px">
          <div v-for="(list, index) in link" :key="index" ref="Div">
            <h2 @click="menu(index)">
              {{ list.name
              }}<svg-icon
                icon-class="ic-arrow-a"
                :style="{
                  transform: list.isSelect ? 'rotate(0deg)' : 'rotate(-180deg)',
                }"
              />
            </h2>
            <el-collapse-transition>
              <ul v-show="list.isSelect">
                <li v-for="item in list.children" :key="item.path">
                  <router-link :to="item.path">{{ item.name }} </router-link>
                  <!-- <span v-if="item.num"
                    ><i>{{ item.num | Num }}</i></span
                  > -->
                </li>
              </ul>
            </el-collapse-transition>
          </div>
        </el-aside>
        <el-main><router-view></router-view></el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "personal-center",

  data() {
    return {
      link: [
        {
          name: "我的订单",
          isSelect: false,
          children: [
            {
              name: "全部订单",
              path: "/PersonalCenter/AllOrders",
            },
            {
              name: "待付款订单",
              path: "/PersonalCenter/OrderPendingPayment",
              num: 2,
            },
            {
              name: "待发货订单",
              path: "/PersonalCenter/BacklogOrder",
              num: 100,
            },
            {
              name: "待收货订单",
              path: "/PersonalCenter/OrderToBeReceived",
            },
          ],
        },
        {
          name: "账户设置",
          isSelect: false,
          children: [
            {
              name: "用户资料",
              path: "/PersonalCenter/UserData",
            },
            {
              name: "收货地址",
              path: "/PersonalCenter/ShippingAddress",
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // console.log(val);
        if (
          val.name === "all-orders" ||
          val.name === "order-pending-payment" ||
          val.name === "backlog-order" ||
          val.name === "order-to-be-received"
        ) {
          this.link[0].isSelect = true;
        } else if (
          val.name === "data-page" ||
          val.name === "shipping-address"
        ) {
          this.link[1].isSelect = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  filters: {
    //处理函数
    Num(i) {
      if (i >= 99) {
        i = "99+";
      }
      return i;
    },
  },
  created() {
    // this.$store.dispatch("personalUser/getEnterpriseId");//获取购物车列表
  },

  methods: {
    menu(i) {
      this.link[i].isSelect = !this.link[i].isSelect;
    },
  },
};
</script>

<style lang="scss" scoped>
#personal-center {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  .title {
    width: 100%;
    height: 56px;
    background: #00aeeb;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 1200px;
      display: flex;
      align-items: center;
      h1 {
        width: 180px;
        height: 56px;
        background: rgba(2, 53, 71, 0.15);
        text-align: center;
        line-height: 70px;
      }
      a {
        width: 140px;
        height: 30px;
        border: 1px solid #ffffff;
        border-radius: 15px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        color: #ffffff;
        margin-left: 20px;
      }
    }
  }
  .content {
    width: 1200px;
    .el-aside {
      background-color: #ffffff;
      padding: 15px 20px;
      div {
        margin-bottom: 6px;
        h2 {
          font-size: 17px;
          font-weight: bold;
          height: 26px;
          color: #333333;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .svg-icon {
            color: #999;
            font-size: 12px;
            transform: rotate(-180deg);
            transition: all 0.5s;
          }
        }
      }
      ul {
        margin: 5px 0;
        overflow: hidden;
        transition: all 0.5s;
        li {
          padding: 5px 0;
          text-indent: 17px;
          font-size: 14px;
          line-height: 20px;
          a {
            color: #666666;
          }
          .router-link-active {
            color: #00aeeb;
          }
          span {
            height: 16px;
            padding: 0 4px;
            text-indent: 1px;
            display: inline-block;
            border-radius: 8px;
            background: red;
            color: #fff;
            font-size: 12px;
            line-height: 16px;
            i {
              position: relative;
              left: -1px;
            }
          }
        }
      }
    }
    .el-main {
      padding: 0;
      width: 1000px;
      background-color: #ffffff;
      flex: none;
      margin-top: 20px;
      margin-left: 20px;
    }
  }
}
</style>